const defaultStarred = []

const initialState = {
  itens: [],
  discount: 0,
  discountpct: 0,
  total: 0,
  totalo: 0,
  attItens: true,
  subtotal: 0,
  ipi: 0,
  totalprod: 0,
  qtditens: 0,
  qtdpecas: 0
}

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ITENS":
      var subtotal = 0;
      var total = 0;
      var ipi = 0;
      var totalprod = 0;
      var qtditens = action.payload.length;
      var qtdpecas = 0;
      var discount = 0;

      var arr = [];
      var checkout = [];
      if(JSON.parse(localStorage.checkout ? localStorage.checkout : null))
        checkout = JSON.parse(localStorage.checkout ? localStorage.checkout : null);


      if(qtditens > 1) {
        action.payload.map(prod => {
          prod.ValorCheioB = prod.ValorCheio
          var novoDesc = (prod.ValorCheioB*(state.discountpct/100))
          var novoValor = prod.ValorCheioB - novoDesc
          discount = discount + (novoDesc*(prod.Quantidade?prod.Quantidade:1))
          prod.ValorCheio = novoValor
          prod.ValorProd = novoValor
          prod.ValProd = novoValor
          prod.Valor = novoValor + (novoValor*(prod.PerIPI/100))
          prod.Valor2 = novoValor + (novoValor*(prod.PerIPI/100))
          prod.ValIPI = (novoValor*(prod.PerIPI/100))
          prod.ValIPI2 = (novoValor*(prod.PerIPI/100))
          prod.Desconto = (novoDesc*(prod.Quantidade?prod.Quantidade:1))

          var p = checkout.findIndex(i => (i.id+''+i.unid) == (prod.CodProduto+''+prod.Referencia))
          if(p > -1) {
            if(checkout[p].qtd <= prod.Estoque || checkout[p].VdaFab === 'S' || checkout[p].AdicionaMilEstoq === 'S')
              arr.push(checkout[p]);
          }
          
          // - ((action.payload[0].Desconto?+(Math.round(action.payload[0].Desconto + "e+2")  + "e-2"):0)*(action.payload[0].Quantidade?action.payload[0].Quantidade:1))
          
          subtotal = subtotal + (((prod.ValorCheioB?prod.ValorCheioB:0)*(prod.Quantidade?prod.Quantidade:1)));
          total = total + (((prod.Valor?prod.Valor:0)*(prod.Quantidade?prod.Quantidade:1)));
          ipi = ipi + ((prod.ValIPI?prod.ValIPI:0)*(prod.Quantidade?prod.Quantidade:1));
          totalprod = totalprod + ((prod.ValorCheioB?prod.ValorCheioB:0)*(prod.Quantidade?prod.Quantidade:1));
          qtdpecas = qtdpecas + (prod.Quantidade?prod.Quantidade:1);
        })
  
        localStorage.checkout = arr === [] ? null : JSON.stringify(arr);
      }
      else if(action.payload.length == 1) {
        action.payload.map(prod => {
          prod.ValorCheioB = prod.ValorCheio
          var novoDesc = (prod.ValorCheioB*(state.discountpct/100))
          var novoValor = prod.ValorCheioB - novoDesc
          discount = discount + (novoDesc*(prod.Quantidade?prod.Quantidade:1))
          prod.ValorCheio = novoValor
          prod.ValorProd = novoValor
          prod.ValProd = novoValor
          prod.Valor = novoValor + (novoValor*(prod.PerIPI/100))
          prod.Valor2 = novoValor + (novoValor*(prod.PerIPI/100))
          prod.ValIPI = (novoValor*(prod.PerIPI/100))
          prod.ValIPI2 = (novoValor*(prod.PerIPI/100))
          prod.Desconto = (novoDesc*(prod.Quantidade?prod.Quantidade:1))

          var p = checkout.findIndex(i => (i.id+''+i.unid) == (prod.CodProduto+''+prod.Referencia))
          if(p > -1) {
            if(checkout[p].qtd <= prod.Estoque || checkout[p].VdaFab === 'S' || checkout[p].AdicionaMilEstoq === 'S')
              arr.push(checkout[p]);
          }
                    
          subtotal = subtotal + (((prod.ValorCheioB?prod.ValorCheioB:0)*(prod.Quantidade?prod.Quantidade:1)));
          total = total + (((prod.Valor?prod.Valor:0)*(prod.Quantidade?prod.Quantidade:1)));
          ipi = ipi + ((prod.ValIPI?prod.ValIPI:0)*(prod.Quantidade?prod.Quantidade:1));
          totalprod = totalprod + ((prod.ValorCheioB?prod.ValorCheioB:0)*(prod.Quantidade?prod.Quantidade:1));
          qtdpecas = qtdpecas + (prod.Quantidade?prod.Quantidade:1);
        })
  
        localStorage.checkout = arr === [] ? null : JSON.stringify(arr);
      } 
      else {
        total = 0;
        discount = 0;
        ipi = 0;
        totalprod = 0;
        qtdpecas = 0;
        qtditens = 0;
      }

      
      return {
        ...state,
        itens: action.payload,
        discount,
        total,
        attItens: true,
        subtotal: total,
        ipi,
        subtotal: subtotal+ipi,
        totalprod,
        qtdpecas,
        qtditens
      }
    case 'SET_DESCONTO':
      var total = 0;
      var ipi = 0;
      var totalprod = 0;
      var qtditens = state.itens.length;
      var qtdpecas = 0;
      var discount = 0;
      var subtotal = 0;

      localStorage.PerDesc = action.payload

      state.itens.map(prod => {
        var novoDesc = (prod.ValorCheioB*(action.payload/100))
        var novoValor = prod.ValorCheioB - novoDesc
        discount = discount + (novoDesc*(prod.Quantidade?prod.Quantidade:1))
        prod.ValorCheio = novoValor
        prod.ValorProd = novoValor
        prod.ValProd = novoValor
        prod.Valor = novoValor + (novoValor*(prod.PerIPI/100))
        prod.Valor2 = novoValor + (novoValor*(prod.PerIPI/100))
        prod.ValIPI = (novoValor*(prod.PerIPI/100))
        prod.ValIPI2 = (novoValor*(prod.PerIPI/100))
        prod.Desconto = (novoDesc*(prod.Quantidade?prod.Quantidade:1))

        subtotal = subtotal + (((prod.ValorCheioB?prod.ValorCheioB:0)*(prod.Quantidade?prod.Quantidade:1)));
        total = total + (((prod.Valor?prod.Valor:0)*(prod.Quantidade?prod.Quantidade:1)));
        ipi = ipi + ((prod.ValIPI?prod.ValIPI:0)*(prod.Quantidade?prod.Quantidade:1));
        totalprod = totalprod + ((prod.ValorCheioB?prod.ValorCheioB:0)*(prod.Quantidade?prod.Quantidade:1));
        qtdpecas = qtdpecas + (prod.Quantidade?prod.Quantidade:1);
      })

      return {
        ...state,
        discountpct: action.payload,
        discount,
        total,
        attItens: true,
        subtotal: total,
        ipi,
        subtotal: subtotal+ipi,
        totalprod,
        qtdpecas,
        qtditens
      }
    default:
      return state
  }
}

export default checkoutReducer
