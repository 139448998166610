import { coreBase } from '../../../configs/coreBase';

export const buscarCarrinho = () => {
  return async (dispatch, getState) => {
    var finalItens = [];
    var checkout = [];

    if(JSON.parse(localStorage.checkout ? localStorage.checkout : null))
      checkout = JSON.parse(localStorage.checkout ? localStorage.checkout : null);
    
    var carrinhoAtual = checkout ? checkout.map(i => i.id).join(',') : '0';
    const { shop } = getState();
    var r = await coreBase.get(`/ecommerce/carrinho/${carrinhoAtual}/${shop.modo}`);
    
    if(r) {
      r.data.map(item => {
        var indexf = finalItens.findIndex(f => f.CodProduto == item.CodProduto && f.Referencia == item.Referencia);
        if(indexf > -1) {
          finalItens[indexf].images.push(item.image);
          return;
        }
  
        var indexc = checkout.findIndex(c => c.id == item.CodProduto && c.unid == item.Referencia);
        if(indexc > -1) {
          if(checkout[indexc].qtd <= item.Estoque || checkout[indexc].VdaFab === 'S' || checkout[indexc].AdicionaMilEstoq === 'S')
            finalItens.push({
              ...item,
              Quantidade: checkout[indexc].qtd,
              images: [item.image]
            });
        }
      });
    }

    dispatch({
      type: "SET_ITENS",
      payload: finalItens
    });
  }
}


export const setDesconto = (desconto) => {
  return (dispatch) => {
    dispatch({
      type: "SET_DESCONTO",
      payload: desconto
    });
  }
}

export const setItens = (item) => {
  return async (dispatch, getState) => {
    const { checkout } = getState();
    var itens = checkout.itens;
    itens.push(item);

    dispatch({
      type: "SET_ITENS",
      payload: itens
    });
  }
}

export const removeItem = (index) => {
  return async (dispatch, getState) => {
    const { checkout } = getState();
    var itens = checkout.itens;
    itens.splice(index, 1);

    dispatch({
      type: "SET_ITENS",
      payload: itens
    });
  }
}

export const updateItem = (qtd, index) => {
  return async (dispatch, getState) => {
    const { checkout } = getState();
    var itens = checkout.itens;
    itens[index].Quantidade = qtd;

    dispatch({
      type: "SET_ITENS",
      payload: itens
    });
  }
}