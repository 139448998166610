import api from './api';
import { history } from '../history';
import { getCodByPermission } from '../utility/utils';

export const authenticate = async (user) => {
  try {
    const response = await api.post("/sessions", {
      username: user.email,
      password: user.password
    });
    if(response.data) {
      var loggedInUser;
      loggedInUser = response.data
    
      localStorage.setItem('id', response.data.id);
      localStorage.setItem('username', user.email);
      localStorage.setItem('password', user.password);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('permissoes', response.data.permissoes);
      localStorage.setItem('codven', getCodByPermission('CodVen', response.data.permissoes));
      localStorage.setItem('language', 'pt');
    
      return true;
    } else {
      // if(window.location.href.indexOf('/pedido') > -1) return;
      window.location.href = '/';
      localStorage.clear();
      return false;
    }
  } catch (error) {
    // if(window.location.href.indexOf('/pedido') > -1) return;
    window.location.href = '/';
    localStorage.clear();
    return false;
  }
}