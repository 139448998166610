import { coreBase } from '../../../configs/coreBase';

export const buscarProdutos = (resetar = false) => {
  return async (dispatch, getState) => {
    if(resetar) {
      dispatch({
        type: "RESETAR_FILTROS",
        payload: []
      });
    } else {
      dispatch({
        type: "SET_PRODUTOS",
        payload: []
      });
    }
    
    const { shop } = getState();

    var produtos = [];
    var qtdItens = 0;
    var r = await coreBase.get(`/ecommerce/produtosWeb/${shop.skip*9}/${shop.filter}/${shop.initRange}/${shop.endRange}/${shop.group}/${shop.orderby}/${shop.estoque}/${shop.modo}/${shop.vdaFutura}`);
    if(r) {
      r.data.map(item => {
        var f = produtos.findIndex(p => p.CodProduto == item.CodProduto && p.Referencia == item.Referencia)
        if(f > -1) {
          produtos[f].images.push(item.image);
          return;
        }
        
        var checkout = [];
        if(JSON.parse(localStorage.checkout ? localStorage.checkout : null)) checkout = JSON.parse(localStorage.checkout ? localStorage.checkout : null);
    
        var i = checkout ? checkout.findIndex(i => (i.id+''+i.unid) == (item.CodProduto+''+item.Referencia)) : -1
        if(i > -1) {
          item.price = item.price2 * checkout[i].qtd;
          item.precosipi = item.ValProd * checkout[i].qtd;
          item.ValIPI = item.ValIPI2 * checkout[i].qtd;
          item.qtd = checkout[i].qtd;
        } else {
          item.precosipi = item.ValProd;
        }
          
        produtos.push({
          ...item,
          images: [item.image]
        });
      });
    }

    dispatch({
      type: "SET_PRODUTOS",
      payload: produtos
    });

    var r2 = await coreBase.get(`/ecommerce/qtdItens/${shop.filter}/${shop.initRange}/${shop.endRange}/${shop.group}/${shop.orderby}/${shop.estoque}/${shop.vdaFutura}`);
    if(r2) qtdItens = r2.data;

    dispatch({
      type: "SET_QTDITENS",
      payload: qtdItens
    });
  }
}

export const setFilter = (filter) => {
  return (dispatch) => {
    dispatch({
      type: "SET_FILTER",
      payload: filter
    });

    buscarProdutos();
  }
}

export const setModo = (modo) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MODO",
      payload: modo
    });
  }
}

export const setCodCli = (codcli) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CODCLI",
      payload: codcli
    });
  }
}

export const setCodCid = (codcid) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CODCID",
      payload: codcid
    });
  }
}

export const setActivePage = (activePage) => {
  return dispatch => {
    dispatch({
      type: "SET_ACTIVE_PAGE",
      payload: activePage
    });
  }
}

export const setRange = (range) => {
  return dispatch => {
    dispatch({
      type: "SET_RANGE",
      payload: range
    })
  }
}

export const setGroup = (group) => {
  return dispatch => {
    dispatch({
      type: "SET_GROUP",
      payload: group
    })
  }
}

export const setOrderBy = (orderby) => {
  return dispatch => {
    dispatch({
      type: "SET_ORDERBY",
      payload: orderby
    })
  }
}

export const setEstoque = (estoque) => {
  return dispatch => {
    dispatch({
      type: "SET_ESTOQUE",
      payload: estoque
    })
  }
}

export const setVendaFutura = (estoque) => {
  return dispatch => {
    dispatch({
      type: "SET_VENDAFUTURA",
      payload: estoque
    })
  }
}