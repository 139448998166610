import api from "./api";
import { authenticate } from "./auth";

const credentials = {
  username: localStorage.username,
  password: localStorage.password
}

export const coreBase = {
  get: async (url, headers) => {
    var response;

    try {
      response = await api.get(url, {
        headers: {
          Authorization: 'Bearer '+localStorage.token,
          id: localStorage.id,
          ...headers
        }
      });
      
      return response;

    } catch (error) {
      if(error.message.indexOf("401") >= 0) {
        var r = await authenticate(credentials);
        if(r) {
          await coreBase.post(url, headers);
        }
        else {
          return null;
        }
      }
    }
    
  },
  delete: async (url, headers) => {
    var response;

    try {
      response = await api.delete(url, {
        headers: {
          Authorization: 'Bearer '+localStorage.token,
          id: localStorage.id,
          ...headers
        }
      });
      
      return response;

    } catch (error) {
      if(error.message.indexOf("401") >= 0) {
        var r = await authenticate(credentials);
        if(r) {
          await coreBase.delete(url, headers);
        }
        else {
          return null;
        }
      }
    }
    
  },
  post: async (url, data, headers) => {
    var response;  
    
    try {
      response = await api.post(url, data, {
        headers: {
          Authorization: 'Bearer '+localStorage.token,
          id: localStorage.id,
          ...headers
        }
      });

      return response;
    } catch (error) {
      if(error.message.indexOf("401") >= 0) {
        var r = await authenticate(credentials);
        if(r) {
          await coreBase.post(url, data, headers);
        }
        else {
          return null;
        }
      } else {
        return response;
      }
    }
  }
};
