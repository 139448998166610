import { history } from "../../../history"
import api from "../../../configs/api";

export const loginWithJWT = user => {
  return dispatch => {
    api
      .post("/sessions", {
        username: user.email,
        password: user.password
      })
      .then(response => {
        var loggedInUser

        if (response.data) {
          loggedInUser = response.data

          localStorage.id = response.data.id;
          localStorage.username = user.email;
          localStorage.password = user.password;
          localStorage.permissoes = response.data.permissoes;
          localStorage.token = response.data.token;

          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, loggedInWith: "jwt" }
          })
        }
      })
      .catch(err => console.log(err))
  }
}

export const logoutWithJWT = () => {
  localStorage.clear();
  
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    window.location.href = '/'
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}