import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import shop from "./shop/Index"
import checkoutReducer from "./checkout/index";

const rootReducer = combineReducers({
  customizer: customizer,
  checkout: checkoutReducer,
  auth: auth,
  shop: shop
})

export default rootReducer
