export function getCodByPermission(key, permissao) {
    var arr = permissao.split(';');
    var index = arr.findIndex((e) => e.indexOf(key) > -1);

    if(index > -1) {
        arr = arr[index].split('|');
        if(arr.length > 1) {
            return arr[1];
        } else {
            return null;
        }
    }
}